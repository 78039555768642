import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import Notifications from 'vue-notification';
import VueCookies from 'vue-cookies';
import VueAxios from 'vue-axios';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import liff from '@line/liff';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.config.productionTip = false;

Vue.use(VueCookies);
// Vue.$cookies.config('7d');

Vue.use(VueAxios, axios);
Vue.component('Loading', Loading);

Vue.use(Notifications);

const endpointUrl = 'https://client-app.qsticker.net';
// const endpointUrl = 'http://localhost:8082';
if (liff.isInClient()) {
  liff.init({
    liffId: '1656074648-mqV8NOlx', // Use own liffId
  }).then(() => {
    // fix broken parameter, e.g. https://client.qsticker.net/?code=xxxx&language=zh-TW#/ -> https://client.qsticker.net/#/?code=xxxx&language=zh-TW
    const code = (new URL(window.location.href)).searchParams.get('code');
    const language = (new URL(window.location.href)).searchParams.get('language');
    if (code && language) {
      const redirectUrl = `${endpointUrl}/#/ing/?code=${code}&language=${language}`;
      window.location.href = redirectUrl;
    }
  });
} else {
  const liffState = (new URL(window.location.href)).searchParams.get('liff.state');
  if (liffState) {
    // handling broken url parameter while using LIFF on computers (LIFF will not be loaded in computer Line app)
    // fix broken parameter, e.g. https://client.qsticker.net/?liff.state=%3Fcode%3Dd0c16%26language%3Dzh-TW#/ -> https://client.qsticker.net/#/?code=xxxx&language=zh-TW
    const decodedParam = decodeURIComponent(liffState); // liffState is encoded by URL encoding, so we need to decode it
    const redirectUrl = `${endpointUrl}/#/ing${decodedParam}`;
    window.location.href = redirectUrl;
  }
}

export default new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
